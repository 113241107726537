import React from 'react';
import PartnersSection from '../landing/sections/Partners';
import AboutSection from '../landing/sections/About';
import '../styles/animations.css';
import Info from '../landing/sections/Info';
import Hero from '../landing/sections/Hero';
import Services from '../landing/sections/Services';
import Sectors from '../landing/sections/Sectors';
import Alliances from '../landing/sections/Alliances';
import Reach from '../landing/sections/Reach';
import Form from '../landing/sections/Form';
import Blogs from '../landing/sections/Blogs';
import Navbar from '../landing/components/Navbar';
import { graphql } from 'gatsby';
import CustomPageWrapper from '../components/CustomPageWrapper/CustomPageWrapper';
import Helmet from '../components/Helmet';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function IndexPage() {
  const { t } = useTranslation();
  const title = t('metadata.title');

  const description = t('metadata.description');

  const data = {
    title,
    description,
  };

  return (
    <>
      <Helmet data={data} />

      <CustomPageWrapper>
        <div className='text-white'>
          <Navbar top='20' />
          <header className='relative -top-[56px] sm:-top-[72px] mb-0 sm:mb-12'>
            <Info />
            <Hero />
          </header>
          <main className='relative -top-[36px] overflow-x-hidden'>
            <PartnersSection />
            <AboutSection />
            <Services />
            <Reach />
            <Sectors />
            <Alliances />
            <LazyLoad once height={500} offset={1920}>
              <Blogs />
            </LazyLoad>
            <LazyLoad once height={400}>
              <Form />
            </LazyLoad>
            <span id='contact'></span>
          </main>
        </div>
      </CustomPageWrapper>
    </>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
